export const entityType = {
  channel: 'channel',
  ms: 'ms',
  device: 'device',
  userPrivileges: 'userPrivileges',
  clientSession: 'clientSession',
  clientApplicationSession: 'clientApplicationSession',
  clientSynoptic: 'clientSynoptic',
  channelMonthHistory: 'channelMonthHistory',
  msMonthHistory: 'msMonthHistory',
  remusEvent: 'remusEvent',
  channelHistory: 'channelHistory',
  msHistory: 'msHistory',
  MSDAChannel: 'MSDAChannel',
};

export const entityTypeExportDatas = {
  nxcals: 'Nxcals',
  influx: 'Influx',
};

export const entityTypeNames = {
  channel: 'Channel',
  ms: 'MS',
  device: 'Device',
};

export const favoriteCategoryType = {
  private: 'Private',
  shared: 'Shared',
};

export const chartTypes = {
  column: 'column',
  line: 'line',
  pie: 'pie',
};

export const chartTypeNames = {
  column: 'Bar',
  line: 'Line',
  pie: 'Pie',
};

export const aggregatingTypes = {
  AVG_AGG: 'AVG_AGG',
  MIN_AGG: 'MIN_AGG',
  MAX_AGG: 'MAX_AGG',
  SUM_AGG: 'SUM_AGG',
};

export const specialAggregatingTypes = {
  COUNT_AGG: 'COUNT_AGG',
  PERCENTAGE_AGG: 'PERCENTAGE_AGG',
};

export const erudisResponseStatuses = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  TERMINATION_SUCCESS: 'TERMINATION_SUCCESS',
  EMPTY_RESULT: 'EMPTY_RESULT',
  WEBSOCKET_DISCONNECTED: 'WEBSOCKET_DISCONNECTED', // Not part of erudis ResponseStatus, but helpful for frontend,
  WEBSOCKET_ERROR: 'WEBSOCKET_ERROR',
  NONE: 'NONE',
};

export const RemusWebApps = {
  TRENDS: 'TRENDS',
  MEASUREMENTS_STATISTICS: 'MEASUREMENTS_STATISTICS',
  PARAMETERS: 'PARAMETERS',
  ALARM_EXTRACTION: 'ALARM_EXTRACTION',
  SNAPSHOTS: 'SNAPSHOTS',
  ALARM_SCREEN: 'ALARM_SCREEN',
  MARKDOWN: 'MARKDOWN',
  MS_PARAMETERS: 'MS_PARAMETERS',
  CHANNEL_PARAMETERS: 'CHANNEL_PARAMETERS',
  MS_INFO: 'MS_INFO',
  CHANNEL_INFO: 'CHANNEL_INFO',
  MEASUREMENTS_HEATMAP: 'MEASUREMENTS_HEATMAP',
};

export const BASE_64_IMAGE_PREFIX = 'data:image/png;base64,';

export const SYNOPTIC_IMAGE_PREFIX = 'synoptics/';
