import React, {lazy} from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider
} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {useLanguage, useOnce} from 'react-lib/dist/hooks-es';
import ErrorBoundary from './components/Common/ErrorBoundary';
import AppComponent from "./layout/AppComponent";
import Connector from './api/agent';
import {
  AdminHome,
  AlarmExtraction,
  Alarms,
  ApplicationEditorRoot,
  Availability,
  ChannelHistoryStatistics,
  ChannelMonthHistoryStatistics,
  ChannelStatistics,
  ClientApplicationSessionStatistics,
  ClientSessionStatistics,
  ClientSynopticSessionsStatistics,
  Dashboards,
  DashboardsRoot,
  DataExportStatusHome,
  DataExportStatusInflux,
  DataExportStatusNxcals,
  DeviceStatistics,
  Heatmap,
  Hyetogram,
  MeasurementsStatistics,
  MeteoDataExtraction,
  EnvHeatmap,
  MonitoringGroups,
  MSDAChannelStatistics,
  MSDashboards,
  MSDashboardsRoot,
  MSHistoryStatistics,
  MSMonthHistoryStatistics,
  MSStatistics,
  NetAmbientDoses,
  NewDashboard,
  Notification,
  NotificationList,
  Parameters,
  Pipeline,
  PlatformRoot,
  RemusApplication,
  RemusEventStatistics,
  ReportingHome,
  Snapshots,
  StatisticsHome,
  SynopticsCatalog,
  SynopticWidgetsCatalog,
  SystemNxcals,
  SystemNxcalsByChid,
  SystemNxcalsByType,
  SystemStats,
  Trends,
  UnsubscribeNotifications,
  UserPrivilegesStatistics
} from './routes';

import {initActions} from './behavior/init';
import platforms from './platformConfig';
import i18n from './i18n';
import 'semantic-ui-less/semantic.less';
import 'hse-filtering-library-frontend/lib/index.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './style.css';
import LoadingComponent from './LoadingComponent';
import './mobile.less';

const App = ({ isLoading, start }) => {
  useOnce(() => {
    start();
  });

  const { apiProd } = Connector;

  useLanguage(apiProd, i18n);

  if (isLoading) {
    return <LoadingComponent />;
  }
  if (!platforms) {
    return <h1>Error</h1>;
  }
  if (Object.keys(platforms).length === 0) {
    return <h1>No Platforms</h1>;
  }


  const router = createBrowserRouter(createRoutesFromElements(
    <Route path="/" errorElement={<ErrorBoundary />}>

      <Route path="/:platform" element={<AppComponent />} handle={{ crumb: "REMUS" }}>

        <Route path={AdminHome.getRoute(false, false)} handle={{ crumb: "Admin" }}>
          <Route path={SystemStats.getRoute()}
                 Component={lazy(() => import('./pages/SystemStatsPage'))}
                 handle={{ crumb: "System Statistics" }} />

          <Route path={Heatmap.getRoute()}
                 Component={lazy(() => import('./pages/HeatmapPage'))}
                 handle={{ crumb: "Processes Heatmap" }} />

          <Route path={SystemNxcals.getRoute(false, false)} handle={{ crumb: "NXCALS Publication Management" }}>
            <Route index
                   Component={lazy(() => import('./pages/SystemNxcalsPage/SystemNxcalsPage'))} />

            <Route path={SystemNxcalsByChid.getRoute()}
                   Component={lazy(() => import('./pages/SystemNxcalsPage/SystemNxcalsByChidPage'))}
                   handle={{ crumb: "NXCALS pub. by Channel Id" }} />

            <Route path={SystemNxcalsByType.getRoute()}
                   Component={lazy(() => import('./pages/SystemNxcalsPage/SystemNxcalsByTypePage'))}
                   handle={{ crumb: "NXCALS pub. by Type" }} />
          </Route>

          <Route index
                 Component={lazy(() => import('./pages/AdminPage'))} />
        </Route>


        <Route path={ApplicationEditorRoot.getRoute(false, false)} handle={{ crumb: "Application Editor" }}>
          <Route index
                 Component={lazy(() => import('./pages/ApplicationEditorPage'))} />

          <Route path={SynopticWidgetsCatalog.getRoute()}
                 Component={lazy(() => import('./pages/SynopticWidgetsCatalogPage'))}
                 handle={{ crumb: "Manage Widgets" }} />

          <Route path={SynopticsCatalog.getRoute()}
                 Component={lazy(() => import('./pages/SynopticsCatalogPage'))}
                 handle={{ crumb: "Manage Synoptics Backgrounds" }} />

          <Route path={RemusApplication.getRoute()}
                 Component={lazy(() => import('./pages/RemusApplicationPage'))}
                 handle={{ crumb: "Edit" }} />
        </Route>


        <Route path={DashboardsRoot.getRoute(false, false)} handle={{ crumb: "Dashboards" }}>
          <Route index
                 Component={lazy(() => import('./pages/DashboardsPage'))} />

          <Route path={Dashboards.getRoute()}
                 Component={lazy(() => import('./pages/DashboardPage'))}
                 handle={{ crumb: "Dashboard" }} /> {/* TODO */}

          <Route path={NewDashboard.getRoute()}
                 Component={lazy(() => import('./pages/DashboardPage'))}
                 handle={{ crumb: "New" }} />

          <Route path={MSDashboardsRoot.getRoute(false, false)}>
            <Route path={MSDashboards.getRoute()}
                   Component={lazy(() => import('./pages/MSDashboardsPage'))}
                   handle={{ crumb: "MS Dashboards" }} />
          </Route>
        </Route>


        <Route path={DataExportStatusHome.getRoute(false, false)} handle={{ crumb: "Data Export" }}>
          <Route index
                 Component={lazy(() => import('./pages/DataExportStatus/DataExportStatusHomePage'))} />

          <Route path={DataExportStatusNxcals.getRoute()}
                 Component={lazy(() => import('./pages/DataExportStatus/DataExportStatusNxcalsPage'))}
                 handle={{ crumb: "NXCALS" }} />

          <Route path={DataExportStatusInflux.getRoute()}
                 Component={lazy(() => import('./pages/DataExportStatus/DataExportStatusInfluxPage'))}
                 handle={{ crumb: "InfluxDB" }} />
        </Route>

        <Route path={NotificationList.getRoute(false, false)} handle={{ crumb: "Notifications" }}>
          <Route index
                 Component={lazy(() => import('./pages/NotificationPage/NotificationListPage'))} />
          <Route path={UnsubscribeNotifications.getRoute()}
                 Component={lazy(() => import('./pages/NotificationPage/UnsubscribeNotifications'))}
                 handle={{ crumb: "Unsubscribe" }} />
          <Route path={Notification.getRoute()}
                 Component={lazy(() => import('./pages/NotificationPage/NotificationEditPage'))}
                 handle={{ crumb: "Edit" }} /> {/* TODO */}
        </Route>

        <Route path={ReportingHome.getRoute(false, false)} handle={{ crumb: "Reporting" }}>
          <Route index
                 Component={lazy(() => import('./pages/ReportingPage'))} />
          <Route path={Snapshots.getRoute()}
                 Component={lazy(() => import('./pages/SnapshotsPage'))}
                 handle={{ crumb: "Snapshots Report" }} />
          <Route path={Parameters.getRoute()}
                 Component={lazy(() => import('./pages/ParametersPage'))}
                 handle={{ crumb: "Parameters Report" }} />

          <Route path={AlarmExtraction.getRoute()}
                 Component={lazy(() => import('./pages/AlarmExtractionPage'))}
                 handle={{ crumb: "Alarm Extraction" }} />

          <Route path={Availability.getRoute()}
                 Component={lazy(() => import('./pages/AvailabilityPage'))}
                 handle={{ crumb: "Availability" }} />

          <Route path={Hyetogram.getRoute()}
                 Component={lazy(() => import('./pages/HyetogramPage'))}
                 handle={{ crumb: "Hyetogram" }} />

          <Route path={MeteoDataExtraction.getRoute()}
                 Component={lazy(() => import('./pages/MeteoDataExtractionPage'))}
                 handle={{ crumb: "Meteo Data Extraction" }} />

          <Route path={MeasurementsStatistics.getRoute()}
                 Component={lazy(() => import('./pages/MeasurementsStatisticsPage'))}
                 handle={{ crumb: "Measurements Statistics" }} />

          <Route path={EnvHeatmap.getRoute()}
                 Component={lazy(() => import('./pages/EnvHeatmapPage'))}
                 handle={{crumb: "Measurements Heatmap"}}/>

          <Route path={NetAmbientDoses.getRoute(false, false)} handle={{ crumb: "Measurements Processing" }}>
            <Route index
                   Component={lazy(() => import('./pages/NetAmbientDosesPage'))} />

            <Route path={Pipeline.getRoute()}
                   Component={lazy(() => import('./pages/PipelinePage/PipelinePage'))}
                   handle={{ crumb: "Pipeline" }} />
          </Route>

        </Route>

        <Route path={StatisticsHome.getRoute(false, false)} handle={{ crumb: "Statistics" }}>
          <Route index
                 Component={lazy(() => import('./pages/Statistics/StatisticsHomePage'))} />
          <Route path={ChannelStatistics.getRoute()}
                 Component={lazy(() => import('./pages/Statistics/ChannelStatisticsPage'))}
                 handle={{ crumb: "Channel" }} />
          <Route path={MSStatistics.getRoute()}
                 Component={lazy(() => import('./pages/Statistics/MSStatisticsPage'))}
                 handle={{ crumb: "MS" }} />
          <Route path={DeviceStatistics.getRoute()}
                 Component={lazy(() => import('./pages/Statistics/DeviceStatisticsPage'))}
                 handle={{ crumb: "Device" }} />
          <Route path={RemusEventStatistics.getRoute()}
                 Component={lazy(() => import('./pages/Statistics/RemusEventStatisticsPage'))}
                 handle={{ crumb: "Events" }} />
          <Route path={ChannelHistoryStatistics.getRoute()}
                 Component={lazy(() => import('./pages/Statistics/ChannelHistoryStatisticsPage'))}
                 handle={{ crumb: "Channel History" }} />
          <Route path={MSHistoryStatistics.getRoute()}
                 Component={lazy(() => import('./pages/Statistics/MSHistoryStatisticsPage'))}
                 handle={{ crumb: "MS History" }} />
          <Route path={ChannelMonthHistoryStatistics.getRoute()}
                 Component={lazy(() => import('./pages/Statistics/ChannelMonthHistoryStatisticsPage'))}
                 handle={{ crumb: "Channel Monthly History" }} />
          <Route path={MSMonthHistoryStatistics.getRoute()}
                 Component={lazy(() => import('./pages/Statistics/MSMonthHistoryStatisticsPage'))}
                 handle={{ crumb: "MS Monthly History" }} />
          <Route path={UserPrivilegesStatistics.getRoute()}
                 Component={lazy(() => import('./pages/Statistics/UserPrivilegesStatisticsPage'))}
                 handle={{ crumb: "User Privileges" }} />
          <Route path={ClientSessionStatistics.getRoute()}
                 Component={lazy(() => import('./pages/Statistics/ClientSessionStatisticsPage'))}
                 handle={{ crumb: "REMUS Sessions" }} />
          <Route path={ClientApplicationSessionStatistics.getRoute()}
                 Component={lazy(() => import('./pages/Statistics/ClientApplicationSessionStatisticsPage'))}
                 handle={{ crumb: "REMUS Application Sessions" }} />
          <Route path={ClientSynopticSessionsStatistics.getRoute()}
                 Component={lazy(() => import('./pages/Statistics/ClientSynopticSessionStatisticsPage'))}
                 handle={{ crumb: "REMUS Synoptics Sessions" }} />
          <Route path={MSDAChannelStatistics.getRoute()}
                 Component={lazy(() => import('./pages/Statistics/MSDAChannelStatisticsPage'))}
                 handle={{ crumb: "MSDA Channels" }} />
        </Route>


        <Route path={Alarms.getRoute()}
               Component={lazy(() => import('./pages/AlarmsPage'))}
               handle={{ crumb: "Alarms" }} />

        <Route path={MonitoringGroups.getRoute()}
               Component={lazy(() => import('./pages/MonitoringGroupsPage'))}
               handle={{ crumb: "Monitoring Group" }} />

        <Route path={Trends.getRoute()}
               Component={lazy(() => import('./pages/TrendsPage'))}
               handle={{ crumb: "Trends" }} />

        <Route index
               Component={lazy(() => import('./pages/HomePage'))}
               handle={{ crumb: "Home" }} />
      </Route>

      <Route index element={<Navigate to={`${PlatformRoot.getLink({ platform: platforms[0] })}`} />} />

    </Route>
  ));

  return (
    <RouterProvider router={router} />
  );
};

App.propTypes = {
  start: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ init }) => ({
  isLoading: init.isLoading,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(initActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
